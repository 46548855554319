import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import JobTabStyling from '../JobTabStyling';
import { Grid, GridCell } from '../Grid';
import { Breadcrumbs } from '../Breadcrumb';
import { NavTabBar } from '../Tabs';
import { ListDivider } from '../List';
import Headline3 from '../Typography/Heading/Headline3';
import Body1 from '../Typography/Body/Body1';
import { markdownStyling } from '../InnerHtmlStyling';
import breakpoints from '../Theme/breakpoints';

const Container = styled.div`
  max-width: 970px;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const JobsGrid = styled(({ hasImage, ...rest }) => <Grid {...rest} />)`
  &&& {
    margin: ${({ hasImage }) => (hasImage ? '0' : '0 0 20px')};
    padding: 0 20px;
    flex-grow: 1;
    flex-direction: column;
  }
`;

JobsGrid.defaultProps = {
  ...JobsGrid.defaultProps,
  hasImage: false,
};

const JobsGridHeaderCell = styled(GridCell)`
  &&& {
    border-bottom: ${(properties) => (
    properties.hideIntroTextOnMobile ? 'none' : '16px solid white')}
    }
`;

const JobsGridCell = styled(GridCell)`
  &&& {
    padding-bottom: 16px;
    background-color: white;
    @media (min-width: ${breakpoints.xl}px) {
      padding: 0 16px 16px;
      min-width: unset;
    }
  }
`;

const JobsListGridCell = styled(GridCell)`
  &&& {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

export const SearchCell = styled(GridCell)`
  min-height: 290px;

  @media (min-width: ${breakpoints.lg}px) {
    min-height: 146px;
  }

  &&&&& {
    margin: ${(properties) => (
    properties.hideIntroTextOnMobile ? '1rem -12px 0' : '-12px')};
    min-width: calc(100% + 24px);
    padding: 0 0 16px;
    align-self: center;
    background-color: white;

    @media (min-width: ${breakpoints.sm}px) {
      margin: -12px;
    }

    @media (min-width: ${breakpoints.xl}px) {
      padding: 0 16px 16px;
      min-width: unset;
    }
  }
`;

const titleOnImageStyle = css`
  font-size: 1.5rem;
  color: black;
  background-color: rgba(239,239,239);
  line-height: 1em;
  padding: 1.5rem 0;
`;

const Title = styled(Headline3)`
  text-align: center;

  && {
    ${({ hasImage }) => hasImage && titleOnImageStyle}
  }

`;

Title.defaultProps = {
  ...Title.defaultProps,
  tag: 'h1',
};

const LogoContainer = styled.div`
  min-height: 14rem;
  display: flex;
  align-items: center;

  /* stylelint-disable-next-line selector-type-no-unknown */
  && progressive-img, img {
    width: 100%;
    --img-width: 100%;
  }

  @media (min-width: ${breakpoints.sm}px) {
    min-height: unset;
    display: block;
    align-items: unset;
    flex: 0 0 220px;
    align-self: center;
    margin: 24px;
    width: 220px;
    min-height: unset;
    display: block;
    align-items: center;
  }
`;

const introductionContainerOnImageStyle = css`
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.sm}px) {
    flex-wrap: nowrap;
    margin: 0 auto;
    background-color: var(--white);
    padding: 24px;
    min-height: 8vw;
  }
`;

const IntroductionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${({ hasTabs }) => (hasTabs ? 'flex-start' : 'center')};
  ${({ hasImage }) => hasImage && introductionContainerOnImageStyle}
  margin: ${(properties) => (
    properties.hideIntroTextOnMobile ? '16px 0 0' : '16px 0')}
`;

const introductionOnImageStyle = css`
  @media (min-width: ${breakpoints.sm}px) {
    text-align: initial;
    line-height: 1.5em;
    margin: 24px 0;
    width: 100%;
  }
`;

const Introduction = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const IntroductionBody = styled(Body1)`
  text-align: center;
  white-space: pre-wrap;
  ${({ hasImage }) => hasImage && introductionOnImageStyle}

  display: ${(properties) => (
    properties.hideIntroTextOnMobile ? 'none' : 'block')
};

  @media (min-width: ${breakpoints.sm}px) {
    display: block;
  }
`;

IntroductionBody.defaultProps = {
  ...IntroductionBody.defaultProps,
  tag: 'div',
};

const Description = styled(JobTabStyling)`
  ${markdownStyling}
  ${({ theme }) => theme.typography.body1}

  img {
    width: 100%;
  }

  iframe {
    background: var(--black);
    border: 0;
    width: 100%;
    min-height: 180px;

    @media (min-width: ${breakpoints.xssm}px) {
      min-height: 270px;
    }

    @media (min-width: ${breakpoints.sm}px) {
      min-height: 337.5px;
    }

    @media (min-width: ${breakpoints.md}px) {
      min-height: 472.5px;
    }

    @media (min-width: ${breakpoints.lg}px) {
      min-height: 523px;
    }

    @supports (aspect-ratio: 16/9) {
      /* stylelint-disable-next-line property-no-unknown */
      aspect-ratio: 16/9;
    }
  }

  h3 {
    margin: 0;
  }

  @media (min-width: ${breakpoints.sm}px) {
    h3 {
      margin: initial;
    }
  }

  margin: ${(properties) => (
    properties.hideIntroTextOnMobile ? '0' : '32px 0')};
`;

const EditButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 24px;

  button {
    border-radius: 0;
    height: 40px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    justify-content: flex-end;
  }
`;

const breadcrumbsOnImageStyle = css`
  @media (min-width: ${breakpoints.sm}px) {
    && {
      display: flex;
      align-items: center;
      padding: 0px;
    }

    :before {
      display: none;
    }
  }
`;

const JobsBreadcrumbs = styled(Breadcrumbs)`
  height: 40px;
  margin: 2px 0 0 0;

  :before {
    background-color: transparent;
  }
  ${({ hasImage }) => hasImage && breadcrumbsOnImageStyle}
`;

const JobsTabContainer = styled.div`
  position: relative;
`;

export const JobsTabBar = styled(NavTabBar)`
  margin: 0 0 16px;
  .MuiTabs-flexContainer {
    border-bottom: 2px solid var(--grey-light);
  }

  .MuiTab-root {
    font-size: 0.85rem;
    letter-spacing: 0;
    flex-grow: 1;
    max-width: none;
  }
`;

const Fade = styled.div`
  display: inline;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: -1px;
  height: 100%;
  width: 10%;
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,
    rgba(255,255,255,1) 100%);

  @media (min-width: ${breakpoints.sm}px) {
    width: 5%;
  }
`;

const StyledListDivider = styled(ListDivider)`
  display: ${(properties) => (
    properties.hideIntroTextOnMobile ? 'none' : 'block')};

  @media (min-width: ${breakpoints.sm}px) {
    display: block;
  }
`;

const BannerWithTitle = styled.div`
  ${({ hasImage }) => !hasImage && 'margin-top: 0rem;'}
`;

const Jobs = ({
  breadcrumbs,
  title,
  tabs,
  introduction,
  description,
  profileEditButton,
  search,
  children,
  headerImage,
  logo,
  hideIntroTextOnMobile,
  hasTabs,
}) => (
  <>
    {
      !headerImage
      && (
      <Container>
        <JobsGrid spacing={0} hasImage={Boolean(headerImage)}>
          <JobsBreadcrumbs
            crumbs={breadcrumbs}
            hasImage={Boolean(headerImage)}
          />
        </JobsGrid>
      </Container>
      )
    }
    <BannerWithTitle hasImage={Boolean(headerImage)}>
      {headerImage}
      <Title hasImage={Boolean(headerImage)}>
        {title}
      </Title>
    </BannerWithTitle>
    <Container>
      <JobsGrid spacing={0} hasImage={Boolean(headerImage)}>
        <JobsGridHeaderCell hideIntroTextOnMobile={hideIntroTextOnMobile}>
          {
          headerImage
          && (
            <JobsBreadcrumbs
              crumbs={breadcrumbs}
              hasImage={Boolean(headerImage)}
            />
          )
        }
          <IntroductionContainer
            hasTabs={hasTabs}
            hasImage={Boolean(headerImage)}
            hideIntroTextOnMobile={hideIntroTextOnMobile}
          >
            {logo && (
            <LogoContainer>
              {logo}
            </LogoContainer>
            )}
            <Introduction>
              {tabs && (
              <JobsTabContainer>
                  {tabs}
                <Fade />
              </JobsTabContainer>
              )}
              <IntroductionBody
                hasImage={Boolean(logo)}
                hideIntroTextOnMobile={hideIntroTextOnMobile}
              >
                {introduction}
              </IntroductionBody>
            </Introduction>
          </IntroductionContainer>
          {profileEditButton && (
          <EditButtonContainer>{profileEditButton}</EditButtonContainer>
          )}
        </JobsGridHeaderCell>
        {description && (
        <JobsGridCell>
          <StyledListDivider hideIntroTextOnMobile={hideIntroTextOnMobile} />
          <Description hideIntroTextOnMobile={hideIntroTextOnMobile}>
            {description}
          </Description>
        </JobsGridCell>
        )}
        {search && (
        <SearchCell
          container
          spacing={3}
          hideIntroTextOnMobile={hideIntroTextOnMobile}
        >
          {search}
        </SearchCell>
        )}
        <JobsListGridCell>
          {children}
        </JobsListGridCell>
      </JobsGrid>
    </Container>
  </>
);

Jobs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.node).isRequired,
  title: PropTypes.node.isRequired,
  tabs: PropTypes.node,
  introduction: PropTypes.node.isRequired,
  description: PropTypes.node,
  profileEditButton: PropTypes.node,
  search: PropTypes.node,
  children: PropTypes.node,
  headerImage: PropTypes.node,
  logo: PropTypes.node,
  hideIntroTextOnMobile: PropTypes.bool,
  hasTabs: PropTypes.bool,
};

Jobs.defaultProps = {
  tabs: undefined,
  description: undefined,
  profileEditButton: undefined,
  search: undefined,
  children: undefined,
  headerImage: undefined,
  logo: undefined,
  hideIntroTextOnMobile: false,
  hasTabs: false,
};

export default Jobs;
